import { useState, useCallback, useEffect, useContext } from "react";
import { useEmblaCarousel } from "../components/riotgame/embla-carousel/react";
// import { EmblaOptionsType } from "../components/riotgame/embla-carousel";
import Locale from "../data/contexts/riot-locale";
import { isRTL } from "../utils/isRTL";

export const useSlider = (options?: any) => {
  const [isPrevButtonEnabled, setIsPrevButtonEnabled] = useState<boolean>(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);

  const locale = useContext(Locale);

  const isRtl = isRTL(locale);

  const [sliderRef, emblaAPI] = useEmblaCarousel({
    ...options,
    isRtl,
  });

  const goToPrevious = useCallback(() => emblaAPI?.scrollPrev(), [emblaAPI]);
  const goToNext = useCallback(() => emblaAPI?.scrollNext(), [emblaAPI]);
  const goTo = useCallback((index) => emblaAPI?.scrollTo(index), [emblaAPI]);

  const onSelect = useCallback(() => {
    if (!emblaAPI) return;

    setActiveSlideIndex(emblaAPI.selectedScrollSnap());
    setIsPrevButtonEnabled(emblaAPI.canScrollPrev());
    setIsNextButtonEnabled(emblaAPI.canScrollNext());
  }, [emblaAPI]);

  useEffect(() => {
    if (!emblaAPI) return;

    onSelect();
    setScrollSnaps(emblaAPI.scrollSnapList());
    emblaAPI.on("select", onSelect);

    return () => {
      emblaAPI.off("select", onSelect);
    };
  }, [emblaAPI, onSelect]);

  return {
    sliderRef,
    data: {
      isPrevButtonEnabled,
      isNextButtonEnabled,
      activeSlideIndex,
      scrollSnaps,
    },
    actions: {
      goToPrevious,
      goToNext,
      goTo,
    },
  };
};
